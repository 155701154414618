<template>
  <div class="relative flex-1">
    <div class="w-full bg-blue-400 text-white p-2 px-4 font-medium text-lg">
      Select Version
    </div>
    <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm">
      <div v-for="version in $store.getters['tradein/versions']" :key="version.id" class="w-1/2">
        <div class="ml-2 mt-2 px-4 py-2 rounded-lg border-2 bg-white border-gray-100 font-bold text-gray-600 cursor-pointer hover:bg-blue-100 hover:border-blue-600 hover:tet-blue-700ml-2 mt-2 px-4 py-2 rounded-lg border-2 bg-white border-gray-100 font-bold text-gray-600 cursor-pointer hover:bg-blue-100 hover:border-blue-600 hover:tet-blue-700" v-on:click="select(version)">
          <div class="font-bold">{{version.name}}</div>
          <div class="flex flex-row font-normal text-center border-t border-white mt-1 pt-1">
            <div class="w-1/6 px-2 border-r border-white truncate">{{version.doors}} drs.</div>
            <div class="w-1/6 px-2 border-r border-white truncate">{{version.seats}} pl.</div>
            <div class="w-1/5 px-2 border-r border-white truncate">{{version.height}} mm.</div>
            <div class="w-1/4 px-2 border-r border-white truncate">{{version.gearbox}}</div>
            <div class="w-1/5 px-2 truncate">{{version.body}}</div>
          </div>
          <div class="flex flex-row font-normal text-center border-t border-white-100 mt-1 pt-1">
            <div class="w-full px-2">{{available(version)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {renderDateRange} from "@/common";

export default {
  name: "Versions",
  data: function () {
    return {}
  },
  methods: {
    select: function (version) {
      this.$store.dispatch("seller/selectVersion", version);
      this.$emit('version-selected', version);
    },
    available: function(version) {
      return "Available from" + renderDateRange(version.start,'') + " " + renderDateRange(version.stop,"until")
    }
  },
}
</script>

<style scoped>

</style>